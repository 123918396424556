<template>
  <div v-if="data?.list">
    <h1 class="text-2xl font-semibold text-gray-900 mb-8">Passagier saldo - Overzicht</h1>

    <UITableResponsive :items="data.list || []" class="text-center w-auto" :headers="['Rit_ID', 'Gegevens', 'Datums', 'Saldo']" :suffix-headers="['Acties']">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-auto" />
          <col class="w-0" />
        </colgroup>
      </template>
      <template v-slot:item-Rit_ID="{ item }">
        <ButtonRitInfo :id="item.id" target="_blank" />
      </template>
      <template v-slot:item-Gegevens="{ item }">
        <div class="flex flex-col gap-2 pr-4">
          <KlantLijn
            class="card gray"
            label="Klant"
            :klant="item.klant"
          />
          <KlantLijn
            class="card gray"
            label="Reiziger"
            :klant="item.reiziger"
          />
        </div>
      </template>
      <template v-slot:item-Datums="{ item }">
        <div class="flex flex-col gap-2 pr-8">
          <div class="flex items-center gap-2">
            <span>{{ dateWrittenTimeString(item.vertrek_datetime) }}</span>
            <label class="label-info ml-0 text-xs">
              <i class="fas fa-users text-base"></i> {{ item.personen_heen || '?' }}
            </label>
          </div>
          <div class="flex items-center gap-2" v-if="item.is_retour">
            <span>{{ dateWrittenTimeString(item.retour_datetime) }}</span>
            <label class="label-info ml-0 text-xs">
              <i class="fas fa-users text-base"></i> {{ item.personen_retour || '?' }}
            </label>
          </div>
          <span v-else class="text-red-500 font-bold">Enkele rit!</span>
        </div>
      </template>
      <template v-slot:item-Saldo="{ item }">
        <span>&euro;&nbsp;{{ item.passagier_saldo.toFixed(2) }}</span>
      </template>
      <template v-slot:item-Acties="{ item }">
        <div class="flex flex-col gap-2">
          <button @click="handleBehandeld(item, true)" :disabled="item.is_behandeld" class="ml-1 btn success">
            {{ item.is_behandeld ? 'Behandeld!' : 'Markeer als behandeld' }}
          </button>
          <button v-if="item.is_behandeld" @click="handleBehandeld(item, false)" class="ml-1 btn error">
            Markeer als onbehandeld
          </button>
        </div>
      </template>

    </UITableResponsive>
    <UITablePagination class="mt-2" :count="data?.count || 0" v-model:limit="limit" v-model:offset="offset" />
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import useGetApi from '@/hooks/useGetApi'
import useApi from '@/hooks/useApi'
import { dateWrittenTimeString } from '@/functions/formatDate'
import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))

import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UITablePagination from '@/components/UI/Table/Pagination.vue'

const api = useApi()
const limit = ref(25)
const offset = ref(0)
const apiData = computed(() => ({ limit: limit.value, offset: offset.value }))

const { data } = useGetApi('FINANCIAL_PASSAGIER_SALDO_LIST', apiData, { watch: true })

const handleBehandeld = async (item, is_behandeld) => {
  await api.post('FINANCIAL_PASSAGIER_SALDO_BEHANDELD', { rit_id: item.id, is_behandeld }).then(() => {
    item.is_behandeld = is_behandeld
  })
}

// const handleOpslaan = async (item) => {
//   console.log('Opslaan', item)
// }
</script>
